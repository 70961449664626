import React from "react";
import Layout from "../components/Layout";

type Props = {};

const index = (props: Props) => {
  return (
    <Layout>
      <div></div>
    </Layout>
  );
};

export default index;
